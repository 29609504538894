const sendMailCta = async (email) => {
  const url =
    "https://forms-eu1.hsforms.com/submissions/v3/public/submit/formsnext/multipart/26786777/3cb5aabb-9ae4-4fc2-b9da-ac386053f35f";
  const payload = {
    embedAtTimestamp: +new Date(),
    formDefinitionUpdatedAt: +new Date(),
    lang: "fr",
    embedType: "REGULAR",
    disableCookieSubmission: "true",
    userAgent:
      navigator.userAgent,
    pageTitle: document.title,
    pageUrl: window.location.href,
    referrer: window.location.href,
    isHubSpotCmsGeneratedPage: false,
    formTarget: "#hbspt-form-89a7afdd-a013-48f5-86cc-dd5a47c5741b",
    locale: "fr",
    timestamp: +new Date(),
    originalEmbedContext: {
      portalId: "26786777",
      formId: "3cb5aabb-9ae4-4fc2-b9da-ac386053f35f",
      region: "eu1",
      target: "#hbspt-form-89a7afdd-a013-48f5-86cc-dd5a47c5741b",
      isBuilder: false,
      isTestPage: false,
      isPreview: false,
      isMobileResponsive: true,
      isInsideFrame: true,
      shellId: 0,
      pageTitle: document.title,
      pageUrl: window.location.href,
      referrer: window.location.href,
    },
    correlationId: "89a7afdd-a013-48f5-86cc-dd5a47c5741b",
    renderedFieldsIds: ["email"],
    captchaStatus: "NOT_APPLICABLE",
    emailResubscribeStatus: "NOT_APPLICABLE",
    isInsideCrossOriginFrame: false,
    source: "forms-embed-1.3317",
    sourceName: "forms-embed",
    sourceVersion: "1.3317",
    sourceVersionMajor: "1",
    sourceVersionMinor: "3317",
    _debug_allPageIds: {},
    _debug_embedLogLines: [
      {
        clientTimestamp: 1686751480711,
        level: "INFO",
        message:
          'Retrieved pageContext values which may be overriden by the embed context: {"pageTitle":"Onlysales blog","pageUrl":window.location.href,"referrer":window.location.href,"userAgent":"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36","isHubSpotCmsGeneratedPage":false}',
      },
      {
        clientTimestamp: 1686751480713,
        level: "INFO",
        message:
          'Retrieved countryCode property from normalized embed definition response: "FR"',
      },
    ],
  }

  const body = new FormData()
  body.append("email", email)
  body.append("hs_context", JSON.stringify(payload))
  
  const response = await fetch(url, {
    method: "POST",
    body,
  })
  
  const data = await response.text()
  return data
}

export default {
  sendMailCta,
}