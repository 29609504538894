<template>
  <div class="home-page">
    <HomeHero />
    <HighlightedArticles />
    <HomeArticles />
    <HomeBigCta />
    <CategoryList />
  </div>
</template>

<script>
import HomeHero from '../components/HomeHero.vue'
import HighlightedArticles from '@/components/HighlightedArticles.vue'
import HomeArticles from '@/components/HomeArticles.vue'
import HomeBigCta from '@/components/HomeBigCta.vue'
import CategoryList from '@/components/CategoryList.vue'

export default {
  name: "HomeView",
  metaInfo: {
    title: 'Onlysales | Blog',
    htmlAttrs: {
      lang: 'fr-FR'
    },
    meta: [
      {charset: 'utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {hid: 'description', name: 'description', content: 'Le blog onlysales.com - Conseils, astuces et offres d\'emploi pour vous aider à atteindre vos objectifs professionnels.'},
      {rel: 'canonical', href: 'https://blog.onlysales.fr'}
    ]
  },

  components: {
    HomeHero,
    HighlightedArticles,
    HomeArticles,
    HomeBigCta,
    CategoryList,
  },

}
</script>

<style lang="scss">

.home-page {
  width: 100%;

  .home-content {
    padding-top: 94px;
    width: 1200px;
    margin: 0 auto;

    .home-sidebar {
      display: inline-block;
      width: 220px;

      .home-cat-nav {
        margin-bottom: 50px;
      }
    }

    .article-list-cnt {
      float: right;
      width: 892px;
    }
  }
}
</style>