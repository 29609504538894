<template>
  <div class="glossary-sidebar desktop">
    <div class="letter-picker">
      <LetterButton
        v-for="letter in letters"
        :key="letter"
        :letter="letter"
        :selected="letter === selectedLetter"
        :disabled="!articles[letter]?.length"
        @click="$emit('letter-click', letter)"
      />
    </div>
  </div>
</template>

<script>
import LetterButton from '@/components/atoms/LetterButton.vue'

export default {
  components: {
    LetterButton,
  },
  props: {
    articles: {
      type: Object,
      required: true,
    },
    selectedLetter: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      letters: 'abcdefghijklmnopqrstuvwxyz'.split(''),
    }
  },
}
</script>

<style lang="scss">
.glossary-sidebar {
  width: 384px;
  position: sticky;
  position: -webkit-sticky;
}

@media (max-width: $desktopContainer) {
.glossary-sidebar {
  width: 100%;
}
}
</style>