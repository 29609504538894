<template>
  <section class="home-hero">
    <div class="home-hero-ctn">
      <div class="home-hero-txt">
        <div class="subheading">RESSOURCES</div>
        <h1 class="heading">Onlysales blog</h1>
        <p class="supporting-text">
          Conseils, astuces et offres d'emploi pour vous aider à atteindre vos
          objectifs professionnels.
        </p>
      </div>
      <MailCta :dark="true"/>
    </div>
  </section>
</template>

<script>
import MailCta from "@/components/atoms/MailCta.vue";

export default {
  name: "HomeHero",
  components: {
    MailCta,
  },
};
</script>

<style lang="scss">
.home-hero {
  width: 100%;
  background: $primary300;
  padding: 96px 0;

  .home-hero-ctn {
    width: $desktopContainer;
    margin: 0 auto;

    .home-hero-txt {
      max-width: 768px;
      margin-bottom: 40px;

      .heading {
        margin: 12px 0 0 0;
      }

      .supporting-text {
        margin: 24px 0 0 0;
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-hero {
    padding: 64px 0;

    .home-hero-ctn {
      width: $mobileContainer;
      overflow: hidden;

      .heading {
        font-size: 40px;
      }
    }
  }
}

</style>
