<template>
  <div class="letter-button" 
    :class="{'letter-button--selected': selected, 'letter-button--disabled': disabled}"
    @click="!disabled && $emit('click')"
  >
    <div class="letter-button-title">
      {{letter}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    letter: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.letter-button {
  display: inline-block;

  width: 40px;
  height: 40px;
  border-radius: 8px;
  padding: 12px;
  background: transparent;

  margin-bottom: 16px;
  margin-right: 12px;

  cursor: pointer;
  text-transform: uppercase;

  color: $neutral800;
  text-align: center;
  font-size: 14px;
  font-family: "Open Sans";
  font-weight: 700;
  line-height: 20px;
  
  &:hover {
    background: $primary100;
  }

  &.letter-button--selected {
    background: $primary300;
  }

  &.letter-button--disabled {
    cursor: default;
    background: transparent;

    color: $neutral400;
  }
}
</style>