<template>
  <div class="breadcrumbs" v-if="isVisible">
    <div class="breadcrumbs-cnt desktop">
      <router-link :to="isGlossary ? '/glossaire' : isJobs ? '/fiches-metiers' : '/'">
        <img :src="homeIcon" class="home-icon" alt="home" width="12px" height="13px"/>
      </router-link>
      <img :src="supIcon" class="sup-icon" alt="next" width="5px" height="8px" />

      <router-link to="/glossaire" v-if="isGlossary">
        Glossaire
      </router-link>
      <router-link to="/fiches-metiers" v-else-if="isJobs">
        Fiches métiers
      </router-link>
      <router-link to="/" v-else>
        Home
      </router-link>

      <template v-if="!isGlossary && !isJobs">
        <img :src="supIcon" class="sup-icon" alt="next" width="5px" height="8px" />
        <router-link v-if="category" :to="`/${category}`">
          {{categoryName}}
        </router-link>
        <router-link v-else :to="''">
          Mentions légales
        </router-link>
      </template>

      <template v-if="slug">
        <img :src="supIcon" class="sup-icon" alt="next" width="5px" height="8px" />
        <router-link :to="''">
          {{articleName}}
        </router-link>
      </template>
    </div>

    <div class="breadcrumbs-cnt mobile">
      <img :src="infIcon" class="inf-icon" alt="retour" width="5px" height="8px"/>
      <router-link v-if="!isGlossary" router-link to="/">
        Tous les articles
      </router-link>
      <router-link v-else to="/glossaire">
        Tous les mots
      </router-link>
    </div>
  </div>
</template>

<script>
import homeIcon from 'front-lib-os/src/assets/home-icon.svg'
import supIcon from 'front-lib-os/src/assets/sup-icon.svg'
import infIcon from 'front-lib-os/src/assets/inf-icon-black.svg'

export default {
  name: "BreadCrumbs",
  data() {
    return {
      homeIcon,
      supIcon,
      infIcon,
    }
  },
  computed: {
    route() {
      return this.$route
    },
    category() {
      return this.$route.params.category
    },
    categoryName() {
      return this.$root.tags && this.$root.tags[this.category]?.name
    },
    isGlossary() {
      return this.$route.name === "glossaryArticle"
    },
    isJobs() {
      return this.$route.name === "jobsArticle"
    },
    slug() {
      return this.$route.params.slug
    },
    articleName() {
      if(this.isGlossary) {
        return this.$root.glossary && this.$root.glossary.find(a => a.slug === this.slug).title
      }

      if(this.isJobs) {
        return this.$root.jobs && this.$root.jobs.find(a => a.slug === this.slug).title
      }

      return this.$root.articles && this.$root.articles.find(a => a.slug === this.slug).title
    },
    isVisible() {
      return this.$route.name !== "home" && this.$route.name !== "glossary" && this.$route.name !== "jobs"
    },
    isTerms() {
      return this.$route.name === "terms"
    }
  }
};
</script>

<style lang="scss">
.breadcrumbs {
  height: 64px;
  background: $neutral25;
  border-bottom: 1px solid $neutral200;

  .breadcrumbs-cnt {
    width: $desktopContainer;
    margin: 0 auto;
    padding-top: 20px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: $neutral600;

    .home-icon {
      height: 13px;
      position: relative;
      top: 1px;
      margin-right: 2px;
    }

    .sup-icon {
      height: 8px;
      margin: 0 18px;
    }

    .inf-icon {
      height: 8px;
      margin: 0 18px 0 0;
    }

    a {
      text-decoration: none;
      color: $neutral600;

      &:last-child {
        font-weight: 600;
        color: $neutral900;
      }
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .breadcrumbs {

    .breadcrumbs-cnt {
      width: $mobileContainer;
    }
  }
}

</style>