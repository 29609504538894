<template>
  <div class="blog-page">
    <ArticleHeader :article="article" />
    <div class="article-main">
      <ArticleContent :article="article" />
      <ArticleSidebar :article="article" />
    </div>
    <ArticleRelated :article="article"/>
    <NewsletterCta />
  </div>
</template>

<script>
import ArticleHeader from '@/components/ArticleHeader.vue';
import ArticleSidebar from '@/components/ArticleSidebar.vue';
import ArticleContent from '@/components/ArticleContent.vue';
import ArticleRelated from '@/components/ArticleRelated.vue';
import NewsletterCta from '@/components/NewsletterCta.vue';

export default {
  metaInfo() {
    return {
      title: 'Onlysales | ' + this.article.metaTitle,
      htmlAttrs: {
        lang: 'fr-FR'
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {hid: 'description', name: 'description', content: this.article.metaDescription},
        {rel: 'canonical', href: 'https://blog.onlysales.fr/' + this.article.tag + '/' + this.article.slug},
      ]
    }
  },

  components: {
    ArticleHeader,
    NewsletterCta,
    ArticleContent,
    ArticleSidebar,
    ArticleRelated,
  },

  data() {
    const slug = this.$route.params.slug
    const meta = this.$root.allArticles.find(m => m.slug === slug)
      // 404 if article not found
    if (!meta) {
      this.$router.push('/')
    }

    const content = require(`@/contrib/articles/${slug}.md`).default

    return {
      slug,
      article: {
        ...(meta || {}),
        content: content || '',
      },
    }
  },
  mounted() {
    const markdown = require(`@/contrib/articles/${this.article.slug}.md`)
    this.article.content = markdown.default
  },
};
</script>

<style lang="scss">
.blog-page {
  background-color: $neutral25;

  .article-main {
    width: $desktopContainer;
    margin: 0 auto;
    
    .article-sidebar {
      float: left;
    }

    .article-content {
      float: right;
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
}

@media screen and (max-width: $desktopContainer) {
  .blog-page {
    .article-main {
      width: $mobileContainer;

      .article-sidebar, .article-content {
        float: none;
      }
    }
  }
}
</style>