<template>
  <div class="category-header">
    <div class="category-header-cnt">
      <div class="resources">Ressources</div>
      <div class="category-title">
        {{ category.name }}
      </div>
      <div class="category-description">{{ category.description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    category() {
      return this.$root.tags[this.$route.params.category];
    },
  },

  mounted() {
    if(!this.category) {
      this.$router.push({ name: 'home' });
    }
  }
};
</script>

<style lang="scss">
.category-header {
  padding: 96px 0;
  background: $neutral100;

  .category-header-cnt {
    width: $desktopContainer;
    margin: 0 auto;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: $neutral900;

    .resources {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;

      margin-bottom: 12px;
    }

    .category-title {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;

      margin-bottom: 24px;
    }

    .category-description {
      max-width: 768px;
    }
  }
}

@media screen and (max-width: $desktopContainer) {
  .category-header {
    padding: 64px 0;

    .category-header-cnt {
      width: $mobileContainer;
      margin: 0 auto;

      .category-title {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
}
</style>