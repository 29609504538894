<template>
  <div class="cstm-button" :class="{dark, black, block, small: size == 'small'}">
    <button @click="() => $emit('click')">
      {{ label }}
      <img v-if="icon" :src="icon" alt="icon" />
    </button>
  </div>
</template>

<script>
export default {
  name: "CtaButton",
  props: {
    label: {
      type: String,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    black: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "medium",
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.cstm-button {
  display: inline-block;

  button {
    height: 56px;
    border: 1px solid $neutral300;
    background: $primary300;
    padding: 16px 24px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    cursor: pointer;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $neutral900;

    outline-color: $primary300;

    img {
      margin-left: 8px;
    }
  }

  &.dark {
    button {
      background: white;
      color: $neutral700;
    }
  }

  &.black {
    button {
      border: 1px solid $neutral900;
      background: $neutral900;
      color: $neutral25;
    }
  }

  &.block {
    display: block;

    button {
      width: 100%;
    }
  }

  &.small {
    button {
      height: 40px;
      padding: 8px 16px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
.cstm-button {
  display: block;
  
  button {
    width: 100%;
  }
}
}
</style>