<template>
  <div class="article-tag" :class="{dark, primary}">
    <span>{{ tagName }}</span>
  </div>
</template>

<script>
export default {
  name: "ArticleTag",
  props: {
    tag: {
      type: String,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tagName() {
      return this.$root.tags[this.tag]?.name || this.tag;
    }
  }
};
</script>

<style lang="scss">
.article-tag {
  padding: 4px 16px;
  background-color: $neutral100;
  display: inline-block;
  mix-blend-mode: normal;
  border-radius: 1000px;

  span {
    color: $neutral600;
    height: 28px;
  }

  &.dark {
    background-color: $neutral25;

    span {
      color: $neutral600;
    }
  }

  &.primary {
    background-color: $primary25;
    border: 1px solid $primary100;
    height: 36px;
  }
}
</style>