<template>
  <div class="jobs-list-item">
    <div class="jobs-list-item-title">
      {{item.title}}
    </div>
    <div class="jobs-list-item-description">
      {{item.description}}
    </div>
    <router-link :to="`/fiches-metiers/${item.slug}`" class="jobs-list-item-link">
      En savoir plus
      <img class="arrow" :src="require('front-lib-os/src/assets/arrow-right.svg')" />
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.jobs-list-item {
  margin: 32px 0;
  padding: 32px;

  border-radius: 16px;
  border: 1px solid $neutral200;
  background: $white;

  .jobs-list-item-title {
    margin-bottom: 16px;

    color: $neutral900;
    font-size: 24px;
    font-family: "Nunito";
    font-weight: 700;
    line-height: 32px;
  }

  .jobs-list-item-description {
    margin-bottom: 32px;

    color: $neutral600;
    font-size: 16px;
    font-family: "Open Sans";
    font-weight: 400;
    line-height: 24px;
  }

  .jobs-list-item-link {
    color: $neutral900;
    font-size: 16px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;

    .arrow {
      position: relative;
      top: 2px;
      left: 8px;
    }
  }
}
</style>