<template>
  <div class="highlighted-articles">
    <div class="highlighted-articles-cnt">
      <h2 class="heading">Articles à la une</h2>
      <div class="highlight-a">
        <ArticleListItem :article="articles[0]" variant="highlighted-big" />
      </div>
      <div class="highlight-b">
        <ArticleListItem :article="articles[1]" variant="highlighted-small" />
        <ArticleListItem :article="articles[2]" variant="highlighted-small" />
      </div>
    </div>
  </div>
</template>

<script>
import ArticleListItem from './ArticleListItem.vue';

export default {
  name: "HighlightedArticles",
  components: {
    ArticleListItem,
  },

  computed: {
    category() {
      return this.$route.params.category || 'global'
    },
    highlights() {
      return this.$root.highlights[this.category] || this.$root.highlights.global
    },
    articles() {
      return this.highlights.map(h => this.$root.allArticles.find(a => a.slug === h))
    },
  }
};
</script>

<style lang="scss">
.highlighted-articles {
  background-color: $neutral25;
  padding: 96px 0;

  .highlighted-articles-cnt {
    width: $desktopContainer;
    margin: 0 auto;

    .heading {
      margin-bottom: 64px;
    }

    .highlight-a {
      width: 592px;
      float: left;
    }

    .highlight-b {
      width: 592px;
      float: right;

      .article-list-item:first-child {
        margin-bottom: 32px;
      }
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
.highlighted-articles {
  padding: 64px 0;

  .highlighted-articles-cnt {
    width: $mobileContainer;

    .highlight-a,
    .highlight-b {
      width: auto;
      float: none;
    }
  }
}
}
</style>