import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TermsView from '../views/TermsView.vue'
import PrivacyView from '../views/PrivacyView.vue'
import BlogArticleView from '../views/BlogArticleView.vue'
import BlogCategoryView from '../views/BlogCategoryView.vue'
import GlossaryView from '../views/GlossaryView.vue'
import GlossaryArticleView from '../views/GlossaryArticleView.vue'
import JobsView from '../views/JobsView.vue'
import JobsArticleView from '../views/JobsArticleView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/glossaire',
    name: 'glossary',
    component: GlossaryView
  },
  {
    path: '/glossaire/:slug',
    name: 'glossaryArticle',
    component: GlossaryArticleView
  },
  {
    path: '/fiches-metiers',
    name: 'jobs',
    component: JobsView
  },
  {
    path: '/fiches-metiers/:slug',
    name: 'jobsArticle',
    component: JobsArticleView
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView
  },
  {
    path: '/:category',
    name: 'blogCategory',
    component: BlogCategoryView,
  },
  {
    path: '/:category/:slug',
    name: 'blogArticle',
    component: BlogArticleView,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(from, to) {
    if(to.query.ns) {
      return
    }
    
    return { x: 0, y: 0 }
  }
})

export default router
