<template>
  <div class="home-big-cta">
    <div class="cnt">
      <div class="heading">
        Publiez vos offres d'emploi gratuitement et trouvez les meilleurs talents!
      </div>
      <div class="supporting-text">
        Atteignez une audience qualifiée et recrutez efficacement grâce à notre plateforme d'offres d'emploi.
      </div>
      <div class="cta-btn">
        <a href="https://jobs.onlysales.fr/poster-un-emploi" target="_blank">
          <CtaButton label="Publier une offre" :black="true" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CtaButton from "@/components/atoms/CtaButton.vue";

export default {
  components: {
    CtaButton,
  },
}
</script>

<style lang="scss">
.home-big-cta {
  background-color: $neutral100;
  padding: 0 0 96px;

  .cnt {
    width: $desktopContainer;
    margin: 0 auto;
    padding: 64px 224px;

    background-color: $primary300;
    border-radius: 16px;
    text-align: center;

    .heading {
      margin-bottom: 20px;
    }

    .supporting-text {
      margin-bottom: 32px;
    }

    .mail-cta {
      width: 522px;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
.home-big-cta {
  padding: 0 0 64px;
  .cnt {
    width: $mobileContainer;
    padding: 40px 24px;

    .heading {
      font-size: 24px;
      line-height: 32px;
    }
  }
}
}
</style>