<template>
  <div id="app">
    <AppHeader :logo="logo" :logoLink="logoLink" />
    <BreadCrumbs />
    <div style="min-height: 60vh">
      <router-view :key="$route.fullPath" />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from "front-lib-os/src/components/AppHeader.vue";
import AppFooter from "front-lib-os/src/components/AppFooter.vue";

import logo from "front-lib-os/src/assets/onlysales-logo.svg";
import ExtLinkIcon from "front-lib-os/src/assets/ext-link.svg";

import BreadCrumbs from "@/components/BreadCrumbs";

export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
    BreadCrumbs,
  },
  data() {
    return {
      logo,
      extLinkIcon: ExtLinkIcon,
    };
  },
  computed: {
    logoLink() {
      const isGlossary = this.$route.path.includes("glossaire")
      return isGlossary ? "/glossaire" : "/"
    }
  },
  methods: {
    onOnlysales() {
      window.open("https://jobs.onlysales.fr");
    },
  },
};
</script>
