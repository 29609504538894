import Vue from 'vue'
import App from './App.vue'
import router from './router'

import VueSocialSharing from 'vue-social-sharing'
import VueMeta from 'vue-meta'
// import i18n from './i18n'

// blog
import metaContrib from '@/contrib/meta.json'
import tags from '@/contrib/tags.json'
import highlights from '@/contrib/highlights.json'

// glossary
import glossary from '@/contrib/glossary.json'

// jobs
import jobs from '@/contrib/jobs.json'

// theme
import osVue from 'front-lib-os/src'
Vue.use(osVue)

Vue.config.productionTip = false

Vue.use(VueSocialSharing)
Vue.use(VueMeta)

new Vue({
  router,
  // i18n,

  render: h => h(App),
  
  data() {
    return {
      allArticles: metaContrib,
      articles: metaContrib.filter(m => !m.isStaging),
      glossary: glossary.slice().sort((a, b) => a.title?.localeCompare(b.title)),
      jobs: jobs.slice().sort((a, b) => a.title?.localeCompare(b.title)),
      tags: tags,
      highlights: highlights
    }
  },

  methods: {
  },

  mounted() {
  }

}).$mount('#app')
