<template>
  <div class="blog-page">
    <GlossaryArticleHeader :article="article" />
    <div class="article-main">
      <ArticleContent :article="article" />
      <ArticleSidebar :article="article" :headersLevel="2" />
    </div>
    <div class="article-cta">
      <JobCta :jobName="article.ctaJobName" />
    </div>
  </div>
</template>

<script>
import GlossaryArticleHeader from '@/components/GlossaryArticleHeader.vue';
import ArticleSidebar from '@/components/ArticleSidebar.vue';
import ArticleContent from '@/components/ArticleContent.vue';
import JobCta from '@/components/JobCta.vue';

export default {
  metaInfo() {
    return {
      title: 'Onlysales | ' + this.article.metaTitle,
      htmlAttrs: {
        lang: 'fr-FR'
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {hid: 'description', name: 'description', content: this.article.metaDescription},
        { rel: "canonical", href: "https://blog.onlysales.fr/glossaire/" + this.article.slug },
      ]
    }
  },

  components: {
    GlossaryArticleHeader,
    ArticleContent,
    ArticleSidebar,
    JobCta,
  },

  data() {
    const slug = this.$route.params.slug
    const meta = this.$root.jobs.find(m => m.slug === slug)
      // 404 if article not found
    if (!meta) {
      this.$router.push('/fiches-metiers')
    }

    const content = require(`@/contrib/jobs/${slug}.md`).default

    return {
      slug,
      article: {
        ...(meta || {}),
        content: content || '',
      },
    }
  },
  mounted() {
    const markdown = require(`@/contrib/jobs/${this.article.slug}.md`)
    this.article.content = markdown.default
  },
};
</script>

<style lang="scss">
.blog-page {
  background-color: $neutral25;

  .article-main {
    width: $desktopContainer;
    margin: 0 auto;
    
    .article-sidebar {
      float: left;
    }

    .article-content {
      float: right;
    }

    .job-cta {
      float: right;
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .article-cta {
    width: $desktopContainer;
    margin: 0 auto;
  }
}

@media screen and (max-width: $desktopContainer) {
  .blog-page {
    .article-main {
      width: $mobileContainer;

      .article-sidebar, .article-content {
        float: none;
      }
    }

    .article-cta {
      width: $mobileContainer;
      margin: 0 auto;
    }
  }
}
</style>