<template>
  <div class="job-cta">
    <HomeCtaBanner
      overline="Ce job vous intéresse ?"
      :title="`Découvrez les entreprises qui recrutent des ${jobName} sur notre job board 100% dédié aux métiers commerciaux.`"
      description="This is a lorem ipsum text."
      ctaLink="https://jobs.onlysales.fr/"
      ctaText="Aller sur Onlysales Jobs"
      :picture="ctaPic"
    />
  </div>
</template>

<script>
import HomeCtaBanner from './HomeCtaBanner.vue';
import ctaPic from '@/assets/cta-2.png'

export default {
  props: {
    jobName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ctaPic,
    }
  },
  components: {
    HomeCtaBanner,
  },
}
</script>

<style lang="scss">
.job-cta {
  width: 800px;
  min-height: 30px;
  margin-bottom: 96px;
  margin-top: -54px;

  background-color: $primary100;
}

@media screen and (max-width: 1024px) {
  .job-cta {
    width: auto;
    margin-bottom: 64px;
    float: none;
    margin-top: 0;
  }
}
</style>