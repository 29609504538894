<template>
  <div id="category" class="category-tabs">
    <div class="category-tab" 
    :class="{ active: active == '' }"
    @click="() => onClick('')">
      <span>Tous</span>
    </div>
    <div
      v-for="category in tags"
      :key="category.slug"
      class="category-tab"
      :class="{ active: category.slug === active }"
      @click="() => onClick(category)"
    >
      <span>{{ category.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryTabs",
  props: {
    active: {
      type: String,
      required: true,
    },
  },

  computed: {
    tags() {
      return this.$root.tags;
    }
  },

  methods: {
    onClick(category) {
      // todo
      if(category == '') {
        if(this.$route.name == 'home') {
          return
        }
        
        this.$router.push('/?ns=true')
      } else {
        if(this.$route.params.category == category.slug) {
          return
        }

        this.$router.push('/' + category.slug + '?ns=true')
      }
    },
  },
}
</script>

<style lang="scss">
.category-tabs {

  .category-tab {
    display: inline-block;
    height: 40px;
    padding: 8px 16px;
    text-transform: capitalize;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    cursor: pointer;
    margin-right: 4px;
  
    &.active {
      border-bottom: 2px solid $primary300;
      cursor: default;
    }
  }
}
</style>

