<template>
  <div class="article-list-item" :class="{'highlighted-big': variant === 'highlighted-big', 'highlighted-small': variant === 'highlighted-small'}">
    <router-link class="article-link" :to="`/${article.tag}/${article.slug}`">
      <div 
        class="article-img" 
        role="img" 
        :aria-label="article.pictureAlt" 
        :title="article.pictureAlt"
        :style="{backgroundImage: `url('${getImg(article.picture)}')`}"
      />
    </router-link>
    <div class="article-txt">
      <div class="article-date">
        {{ article.date }} | {{ article.readTime }} minutes
      </div>

      <router-link class="article-link" :to="`/${article.tag}/${article.slug}`">
        <div class="article-arrow">
          <img :src="linkArrow" alt="article link" />
        </div>
        <div class="article-title heading">{{ article.title }}</div>
      </router-link>
      <router-link class="article-link" :to="`/${article.tag}/${article.slug}`">
        <div class="article-description supporting-text">{{ article.description }}</div>
      </router-link>
      <ArticleTag :tag="article.tag" :dark="variant == 'default'" />
    </div>
  </div>
</template>

<script>
import ArticleTag from '@/components/atoms/ArticleTag.vue';
import LinkArrow from 'front-lib-os/src/assets/link-arrow.svg'

export default {
  name: "ArticleList",
  components: {
    ArticleTag,
  },
  data() {
    return {
      linkArrow: LinkArrow,
    }
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    variant: { // highlighted-big, highlighted, default
      type: String,
      default: 'default',
    },
  },

  computed: {
    primary() {
      return this.$root.$options.primaryColor;
    },
  },

  methods: {
    getImg(name) {
      const img = require(`@/contrib/images/${name}`)
      return img
    }
  }
}
</script>

<style lang="scss">

.article-list-item {
  display: inline-block;
  width: 384px;
  margin-bottom: 32px;
  // height: 504px;

  .article-img {
    width: 384px;
    height: 240px;
    background-position: center 20%;
    background-repeat: no-repeat;
    background-size: cover;

    border-radius: 16px;
    position: relative;
  }

  .article-txt {
    margin-top: 40px;

    .article-date {
      margin-bottom: 16px;
    }

    .article-link {
      text-decoration: none;
      
      .article-title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px;
      }

      .article-arrow {
        float: right;
        width: 33px;
        height: 64px;
        padding-top: 10px;
        padding-left: 23px;

        img {
          width: 10px;
          height: 10px;
        }
      }

    }

    .article-description {
      margin-bottom: 32px;
      color: $neutral600;
    }
  }

  &.highlighted-big {
    width: 592px;
    height: 236px;

    .article-img {
      width: 592px;
      height: 236px;
    }
  }

  &.highlighted-small {
    width: 592px;
    height: 240px;

    .article-img {
      width: 320px;
      height: 240px;
      float: left;
    }

    .article-txt {
      float: right;
      width: 248px;
      margin-top: 0;

      .article-link {
        color: inherit;
        
        .article-arrow {
          display: none;
        }

        .article-title {
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 24px;
        }
      }

      .article-description {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
.article-list-item, .article-list-item.highlighted-big, .article-list-item.highlighted-small {
  width: $mobileContainer;
  height: auto;
  margin-bottom: 32px;
  
  .article-img {
    width: $mobileContainer;
    float: none;
  }

  .article-txt {
    float: none;
    width: auto;
    margin-top: 40px;

    .article-link {
      .article-arrow {
        display: block;
      }
    }

    .article-description {
      display: block;
    }
  }
}
}

</style>