<template>
  <div class="article-content">
    <div id="blog-content" v-html="renderMarkdown(article.content)" />
  </div>
</template>

<script>
import markdownIt from "markdown-it"

export default {
  props: {
    article: {
      type: Object,
      required: true,
    },
  },

  methods: {
    renderMarkdown(content) {
      if(!content)
        return ''

      const md = markdownIt({
        html: true,
        linkify: false,
        typographer: true,
      });

      let result = md.render(content)
      
      // post process

      // <h2>Content</h2> to <h2 id="content">Content</h2>
      result = result.replace(/<h2>(.*?)<\/h2>/g, (match, p1) => {
        return `<h2 id="${p1}">${p1}</h2>`
      })

      // <h3>Content</h3> to <h3 id="content">Content</h3>
      result = result.replace(/<h3>(.*?)<\/h3>/g, (match, p1) => {
        return `<h3 id="${p1}">${p1}</h3>`
      })

      // <p><img src="/art-content-1.jpeg" alt="altText"></p> to <img src="/art-content-1.jpeg" alt="altText">
      result = result.replace(/<p><img src="(.*?)" alt="(.*?)"><\/p>/g, (match, p1, p2) => {
        const pic = require('@/contrib/images/' + p1)
        return `<img src="${pic}" alt="${p2}">`
      })

      // <blockquote><p>Lire aussi to <blockquote class="read-also">Lire aussi
      result = result.replace(/<blockquote>\s*<p>Lire aussi/g, () => {
        return `<blockquote class="read-also"><p>Lire aussi`
      })

      // <h2 id="[C]Intégrer le nouvel employé">[C]Intégrer le nouvel employé</h2>
      result = result.replace(/<h2 id="\[C\](.*?)">\[C\](.*?)<\/h2>/g, (match, p1, p2) => {
        return `<div class="conclusion"><h2 id=${p1}>${p2}</h2>`
      })
      result += '</div>'
      
      // add target="_blank" to all <a> tags if url is external
      result = result.replace(/<a href="http/g, () => {
        return '<a target="_blank" href="http'
      })
      
      return result
    }
  }
}
</script>

<style lang="scss">
.article-content {
  width: 800px;
  min-height: 1000px;
  margin-bottom: 96px;

  #blog-content {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: $neutral600;

    h2 {
      margin-top: 58px;
      margin-bottom: 20px;

      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: $neutral900;
    }

    &>h2:first-child {
      margin-top: 0;
    }

    img {
      width: 100%;
      margin: 48px 0 0;
      border-radius: 12px;
    }

    img + p {
      margin-top: 12px;
      margin-bottom: 48px;

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      &::before {
        content: '> ';
        font-weight: 600;
        padding-left: 1px;
      }
    }

    a {
      color: $primary300;
    }

    blockquote {
      border-left: 2px solid $primary300;
      padding: 12px 24px;
      margin: 56px 0 80px;


      font-family: 'Open Sans';
      font-style: italic;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: $neutral900;

      p {
        margin: 0;
      }

      &.read-also {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        background: $primary25;
        border-radius: 8px;

        a {
          font-weight: 600;
          color: $neutral900;
        }
      }
    }

    .conclusion {
      background: $neutral100;
      padding: 40px;
      border-radius: 16px;
      margin-top: 48px;

      h2 {
        margin-top: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .article-content {
    width: auto;
    margin-bottom: 64px;
  }
}
</style>