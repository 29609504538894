<template>
  <div class="glossary-menu">
    <div class="letter-block" 
      v-for="(letterArticles, letter) in filteredArticles" 
      :key="letter"
    >
      <div class="letter-title">{{ letter }}</div>
      <div class="letter-content">
        <div class="letter-article" v-for="article in letterArticles" :key="article.slug">
          <GlossaryListItem :item="article" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlossaryListItem from '@/components/GlossaryListItem.vue'

export default {
  components: {
    GlossaryListItem,
  },
  props: {
    articles: {
      type: Object,
      required: true,
    },
    selectedLetter: {
      type: String,
      required: false,
    },
    search: {
      type: String,
      required: false,
    },
  },

  computed: {
    notEmptyArticles() {
      return Object.fromEntries(
        Object.entries(this.articles).filter(item => item[1]?.length > 0)
      )
    },
    filteredArticles() {
      if(this.search) {
        return Object.fromEntries(
          Object.entries(this.notEmptyArticles).map(([letter, articles]) => [
            letter,
            articles.filter(article => article.title.toLowerCase().includes(this.search.toLowerCase()))
          ]).filter(item => item[1]?.length > 0)
        )
      }

      if(this.selectedLetter) {
        return {[this.selectedLetter]: this.notEmptyArticles[this.selectedLetter]}
      } 

      return this.notEmptyArticles
    }
  }
}
</script>

<style lang="scss">
.glossary-menu {
  width: 696px;
  min-height: 1000px;
  margin-bottom: 96px;

  .letter-title {
    color: $neutral900;
    text-transform: uppercase;
    font-size: 40px;
    font-family: 'Nunito';
    font-weight: 800;
    line-height: 48px;
  }
}

@media screen and (max-width: 1024px) {
  .glossary-menu {
    width: auto;
    margin-bottom: 64px;
  }
}
</style>