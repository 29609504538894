<template>
  <div class="article-header">
    <div class="cnt">
      <div class="article-cnt">
        <div class="date">
          {{ article.date }} | {{ article.readTime }} minutes
        </div>
        <div class="lastUpdate">
          Dernière mise à jour : 16 mai 2023
        </div>
        <h1 class="heading">{{ article.title }}</h1>
        <p class="description supporting-text">{{ article.description }}</p>
        <div class="article-author">
          <img 
            class="author-img" 
            :src="authors.default.picture"
            :alt="authors.default.name"
            :title="authors.default.name"/>
          <div class="author-name">
            <div class="name">{{ authors.default.name }}</div>
            <div class="descr">{{ authors.default.descr }}</div>
          </div>
        </div>
      </div>
      <div 
        class="article-img"
        role="img" 
        :aria-label="article.pictureAlt" 
        :title="article.pictureAlt"
        :style="{backgroundImage: `url(${getImg(article.picture)})`}"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      authors: {
        default: {
          name: 'Alexis',
          descr: 'CMO - Senior marketer',
          picture: require('@/assets/people/default.jpg'),
        }
      }
    }
  },
  methods: {
    getImg(name) {
      const img = require(`@/contrib/images/${name}`)
      return img
    }
  }
}
</script>

<style lang="scss">
.article-header {
  padding: 96px 0;

  .cnt {
    width: $desktopContainer;
    margin: 0 auto;

    .article-img {
      float: right;
      width: 592px;
      height: 560px;
      border-radius: 16px;

      background-position: center 20%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .article-cnt {
      display: inline-block;
      width: 580px;
      margin-right: 32px;
      min-height: 560px;

      .date {
        margin-bottom: 8px;
      }

      .last-update {
        margin-bottom: 16px;
      }

      .heading {
        margin-bottom: 24px;

        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 60px;
      }
    }

    .article-author {
      margin-top: 48px;
      margin-bottom: 32px;

      .author-img {
        float: left;
        margin-right: 16px;

        border-radius: 200px;
        width: 56px;
        height: 56px;
      }

      .author-name {
        .name {
          color: $neutral900;
          font-size: 18px;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }

        .descr {
          font-family: 'Open Sans';
          color: $neutral600;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  } 
}

@media screen and (max-width: $desktopContainer) {
  .article-header {
    padding: 64px 0;

    .cnt {
      width: $mobileContainer;
      margin: 0 auto;

      .article-img {
        float: none;
        width: 100%;
        height: 320px;
        margin-bottom: 32px;
      }

      .article-cnt {
        width: 100%;
        margin-right: 0;
        min-height: 0;

        .heading {
          font-size: 32px;
          line-height: 40px;
        }
      }
    }
  }
}
</style>