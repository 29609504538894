<template>
  <div class="mail-cta">
    <div class="cta-text-field">
      <TextField label="Saisissez votre e-mail" @input="(mail) => email = mail">
        <template v-slot:helper>
          <div class="hint-text">
            <span v-if="loading" class="loading">
              Chargement...
            </span>
            <span v-else-if="success" class="success">
              Merci pour votre inscription !
            </span>
            <span v-else-if="error" class="error">
              {{ error }}
            </span>
            <span v-else>
              <router-link to="/privacy">Notre politique de confidentialité</router-link>.
            </span>
          </div>
        </template>
      </TextField>
    </div>
    <div class="cta-btn">
      <CtaButton label="Je m'abonne" :black="dark" @click="onSend" />
    </div>
  </div>
</template>

<script>
import TextField from "@/components/atoms/TextField.vue";
import CtaButton from "@/components/atoms/CtaButton.vue";

import hubspot from "@/helpers/hubspotHelper";

export default {
  name: "MailCta",
  components: {
    TextField,
    CtaButton,
  },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      error: '',
      loading: false,
      success: false,
    }
  },
  methods: {
    async onSend() {
      if(this.loading || this.success) return
      this.loading = true
      this.error = ''

      // eslint-disable-next-line no-useless-escape
      if (!this.email.match(/^(?![\w\.@]*\.\.)(?![\w\.@]*\.@)(?![\w\.]*@\.)\w+[\w\.]*@[\w\.]+\.\w{2,}$/)) {
        this.error = 'Addresse email invalide'
        this.loading = false
        return
      }

      try {
        const data = await hubspot.sendMailCta(this.email)
        if(!data || !data.length) {
          console.warn(data)
          throw('No data')
        }
        this.success = true
      } catch (err) {
        console.error(err)
        this.error = 'Une erreur est survenue'
      } finally {
        this.loading = false
      }
    },
  },
};
</script>

<style lang="scss">
.mail-cta {
  .cta-text-field {
    float: left;
  }

  .cta-btn {
    float: left;
    margin-left: 16px;
  }

  .error {
    color: red;
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
.mail-cta {
  .cta-text-field {
    float: none;
  }
  
  .cta-btn {
    float: none;
    margin: 16px 0 0;
  }
}
}
</style>