<template>
  <div class="article-sidebar">
    <div class="chapters desktop">
      <!-- <div class="chapters-title">Table des matières</div> -->
      <div v-for="(chapter, i) in chapters" :key="`${chapter.text}_${i}`" class="chapter" :class="{h3: chapter.level == 3}">
        <a :href="`#${chapter.text}`">{{ chapter.text }}</a>
      </div>
    </div>

    <div class="social">
      <span class="social-item">
        <img :src="icons.link" :alt="`Share the link`" @click="onCopy"/>
      </span>
      <ShareNetwork
        v-for="network in networks"
        :key="network"
        :network="network"
        :url="thisUrl"
        :title="article.title"
        :description="article.description"
        hashtags="onlysales,recrutement,marketing,vente"
      >
        <span class="social-item">
          <img :src="icons[network]" :alt="`Share on ${network}`" width="18px" height="18px" />
        </span>
      </ShareNetwork>
      <div v-if="copied" class="copied">Lien copié !</div>
    </div>

    <div class="related" v-if="related && related.length > 0">
      <div class="related-title">Mots similaires</div>
      <router-link v-for="relatedItem in related" :key="relatedItem" class="related-item" :to="`/glossaire/${relatedItem}`">{{ getGlossaryName(relatedItem) }}</router-link>
    </div>
  </div>
</template>

<script>
const link = require('front-lib-os/src/assets/link-icon.svg')
const twitter = require('front-lib-os/src/assets/twitter-icon.svg')
const facebook = require('front-lib-os/src/assets/fb-icon.svg')
const linkedin = require('front-lib-os/src/assets/lkn-icon.svg')

export default {
  components: {
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    headersLevel: {
      type: Number,
      default: 3,
    }
  },
  data() {
    return {
      copied: false,
      networks: ['twitter', 'facebook', 'linkedin'],
      icons: {
        link,
        twitter,
        facebook,
        linkedin,
      },
    }
  },
  computed: {
    chapters() {
      if(!this.article || !this.article.content) return []
      
      console.log(this.headersLevel)
      return this.article.content
        .split('\n')
        .filter(line => (line.startsWith('## ') && this.headersLevel >= 2) || (line.startsWith('### ') && this.headersLevel >= 3))
        .map(line => ({
          text: line.replace(/#+ /, '').replace(/: *$/, '').replace('[C]', ''),
          level: line.startsWith('## ') ? 2 : 3,
        }))
    },
    thisUrl() {
      return `https://blog.onlysales.fr${this.$route.path}`
    },
    related() {
      if(!this.article || !this.article.related) return []
      return this.article.related.filter(a => a.trim().length > 0)
    }
  },
  methods: {
    onCopy() {
      const el = document.createElement('textarea')
      el.value = this.thisUrl
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.copied = true
    },
    getGlossaryName(slug) {
      return this.$root.glossary.find(item => item.slug === slug)?.title
    }
  }
}
</script>

<style lang="scss">
.article-sidebar {
  width: 384px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;

  .chapters {
    margin-bottom: 40px;

    .chapters-title {
      margin-bottom: 16px;
    }

    .chapter {
      margin-bottom: 12px;
      margin-top: 16px;

      a {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $neutral600;
        text-decoration: none;
      }

      &.h3 {
        margin-top: 0;
        
        a {
          padding-left: 16px;
          font-size: 14px;
          font-weight: 500;

          &::before {
            content: '\2022';
            position: absolute;
            left: 0;
          }
        }
      }

      &:first-child {
        margin-top: 20px;
      }
    }
  }

  .social {
    position: relative;
    padding: 40px 0;
    border-top: 1px solid $neutral200;
    // border-bottom: 1px solid $neutral200;

    .social-item {
      display: inline-block;
      width: 40px;
      height: 40px;

      background-color: white;
      border: 1px solid $neutral300;

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 10px;

      margin-right: 12px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .copied {
      position: absolute;
      bottom: 12px;
      left: 8px;
    }
  }

  .related {
    position: relative;
    padding: 40px 0;
    border-top: 1px solid $neutral200;
    margin-bottom: 40px;

    .related-title {
      padding-bottom: 16px;
      margin-bottom: 16px;

      color: $neutral600;
      font-size: 16px;
      font-family: 'Open Sans';
      font-weight: 400;
      line-height: 24px;
    }

    .related-item {
      display: inline-block;
      border-radius: 10000px;
      border: 1px solid $primary100;
      background: $primary25;
      padding: 8px 16px;
      margin: 0 16px 16px 0;

      color: var(--neutral-600, #475467);
      text-align: center;
      font-size: 14px;
      font-family: 'Open Sans';
      font-weight: 400;
      line-height: 20px;
      text-decoration: none;
    }
  }
}

@media (max-width: $desktopContainer) {
.article-sidebar {
  width: 100%;
}
}
</style>