<template>
  <div class="glossary-hero">
    <div class="glossary-hero-cnt">
      <div class="sur-header">Glossaire</div>
      <h1>Le Wiki OnlySales</h1>
      <p class="glossary-hero-description">
        Explorez notre glossaire spécialisé pour maîtriser le jargon des commerciaux et réussir votre carrière dans le domaine.
      </p>
      <div class="search-bar">
        <TextField 
          label="Recherchez un terme..."
          @input="$emit('search', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TextField from "@/components/atoms/TextField.vue";

export default {
  components: {
    TextField,
  },
  
}
</script>


<style lang="scss">
.glossary-hero {
  background: linear-gradient(180deg, #FFF9EB 0%, rgba(255, 249, 235, 0.00) 100%);
  padding: 96px 0;
  text-align: center;

  .glossary-hero-cnt {
    width: $desktopContainer;
    margin: 0 auto;
    padding: 0 20px;

    .sur-header {
      color: $neutral900;
      font-size: 14px;
      font-family: 'Nunito';
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    h1 {
      margin: 12px 0 24px;

      color: $neutral900;
      font-size: 48px;
      font-family: 'Nunito';
      font-weight: 700;
      line-height: 60px;
    }
   
    p.glossary-hero-description {
      display: inline-block;
      max-width: 768px;
      margin-bottom: 40px;

      color: $neutral900;
      font-size: 18px;
      font-family: 'Open Sans';
      font-weight: 400;
      line-height: 28px;
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
.glossary-hero {
  padding-bottom: 32px;
  
  .glossary-hero-cnt {
    width: $mobileContainer;
  }
}
}
</style>