<template>
  <div class="cstm-input">
    <input type="text" :placeholder="label" @input="(evt) => $emit('input', evt.target.value)" />
    <div class="hint">
      <slot name="helper"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextField",
  props: ['label', 'helper'],
  slots: ['helper'],
};
</script>


<style lang="scss">
.cstm-input {
  display: inline-block;
  width: 360px;

  input {
    height: 56px;
    width: 360px;
    border: 1px solid $neutral300;
    color: $neutral500;
    background: white;
    padding: 16px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    outline-color: $primary300;
  }

  .hint {
    margin-top: 6px;
    padding-left: 4px;
    text-align: left;

    a {
      color: $neutral600;
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .cstm-input {
    width: 100%;
    
    input {
      width: 100%;
    }
  }
}
</style>
