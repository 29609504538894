<template>
  <div class="jobs-page">
    <div class="jobs-main">
      <jobsHero 
        @search="onSearch"
      />
      <div class="jobs-cnt">
        <glossarySidebar 
          :articles="articlesByLetter"
          :selectedLetter="selectedLetter"
          @letter-click="selectedLetter = $event"
        />
        <JobsMenu 
          :articles="articlesByLetter" 
          :selectedLetter="selectedLetter"
          :search="search"
        />
      </div>
    </div>
  </div>
</template>

<script>
import jobsHero from "@/components/JobsHero.vue";
import glossarySidebar from "@/components/GlossarySidebar.vue";
import JobsMenu from "@/components/JobsMenu.vue";

export default {
  metaInfo() {
    return {
      title: "Onlysales | Fiches métiers",
      htmlAttrs: {
        lang: "fr-FR",
      },
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content:
            "Fiches métiers des jobs de la vente et du marketing, par Onlysales",
        },
        { rel: "canonical", href: "https://blog.onlysales.fr/fiches-metiers"}
      ],
    };
  },

  components: {
    jobsHero,
    glossarySidebar,
    JobsMenu,
  },

  data() {
    return {
      selectedLetter: null,
      search: '',
    }
  },

  computed: {
    articles() {
      return this.$root.jobs;
    },
    articlesByLetter() {
      return Object.fromEntries(
        "abcdedfghijklmnopqrstuvwxyz"
          .split("")
          .map((letter) => [
            letter,
            this.articles.filter((article) =>
              article.title.toLowerCase().startsWith(letter)
            ),
          ])
      );
    },
  },
  methods: {
    onSearch(search) {
      this.selectedLetter = null
      this.search = search
    },
  },
};
</script>

<style lang="scss">
.jobs-page {
  background-color: $neutral25;

  .jobs-main {
    margin: 0 auto;

    .jobs-cnt {
      width: $desktopContainer;
      margin: 0 auto;
    }
    
    .glossary-sidebar {
      float: left;
    }

    .jobs-menu {
      float: right;
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
.jobs-page {
  .jobs-main {
    .jobs-cnt {
      width: $mobileContainer;
      padding: 0 20px;
    }
  }
}
}
</style>