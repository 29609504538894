<template>
  <div class="category-list">
    <div class="category-list-cnt">
      <div class="categories">Catégories</div>
      <div class="category-cnt">
        <router-link v-for="tag in tags" :key="tag.name" :to="`/${tag.slug}`">
          <ArticleTag :tag="tag.name" :primary="true"/>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleTag from '@/components/atoms/ArticleTag.vue';

export default {
  name: "CategoryList",
  components: {
    ArticleTag,
  },
  computed: {
    tags() {
      return this.$root.tags;
    }
  },
};
</script>

<style lang="scss">
.category-list {
  background: $neutral100;

  .category-list-cnt {
    padding: 32px 0 96px;
    border-top: solid 1px $neutral300;
    width: $desktopContainer;
    margin: 0 auto;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: $neutral900;

    .categories {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $neutral900;
      text-transform: uppercase;

      margin-bottom: 32px;
    }

    .article-tag {
      margin-right: 15px;
      margin-bottom: 12px;
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
.category-list {
  .category-list-cnt {
    width: $mobileContainer;
  }
}
}

</style>