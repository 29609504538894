<template>
  <div class="article-header">
    <div class="cnt">
      <h1>{{article.title}}</h1>
      <p class="description supporting-text">{{article.description}}</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
.article-header {
  padding: 96px 0;
  background: linear-gradient(180deg, #FFF9EB 0%, rgba(255, 249, 235, 0.00) 100%);

  .cnt {
    width: $desktopContainer;
    margin: 0 auto;

    h1 {
      color: $neutral900;
      font-size: 48px;
      font-family: "Nunito";
      font-weight: 700;
      line-height: 60px;
    }

    .description {
      min-height: 56px;

      color: $neutral900;
      font-size: 18px;
      font-family: "Open Sans";
      font-weight: 400;
      line-height: 28px;
    }
  } 
}

@media screen and (max-width: $desktopContainer) {
  .article-header {
    padding: 64px 0 128px;

    .cnt {
      width: $mobileContainer;
      margin: 0 auto;

      h1 {
        font-size: 32px;
      }

      .description {
        font-size: 18px;
      }
    }
  }
}
</style>