<template>
  <div class="article-newsletter-cta">
    <div class="cnt">
      <div class="heading">
        Restez à l'affût : Inscrivez-vous à notre newsletter !
      </div>
      <div class="supporting-text">
        Découvrez les dernières actualités, offres exclusives et conseils passionnants directement dans votre boîte de réception !
      </div>
      <MailCta dark />
    </div>
  </div>
</template>

<script>
import MailCta from "@/components/atoms/MailCta.vue";

export default {
  components: {
    MailCta,
  },
}
</script>

<style lang="scss">
.article-newsletter-cta {
  background-color: $neutral100;
  padding: 0 0 96px;

  .cnt {
    width: $desktopContainer;
    height: 358px;
    margin: 0 auto;
    padding: 64px 224px;

    background-color: $primary300;
    border-radius: 16px;
    text-align: center;

    .heading {
      margin-bottom: 20px;
    }

    .supporting-text {
      margin-bottom: 32px;
    }

    .mail-cta {
      width: 522px;
      margin: 0 auto;
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .article-newsletter-cta {
    padding: 0 0 64px;

    .cnt {
      width: $mobileContainer;
      height: auto;
      padding: 32px 16px;

      .heading {
        margin-bottom: 16px;
      }

      .supporting-text {
        margin-bottom: 24px;
      }

      .mail-cta {
        width: 100%;
      }
    }
  }
}
</style>